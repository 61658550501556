<template>
  <svg viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.618,15.345l8.666-8.666a2.039,2.039,0,1,1,2.883,2.883L7.461,21.305a4.078,4.078,0,0,1-5.767-5.768L13.928,3.305a5.606,5.606,0,0,1,7.929,7.928L13.192,19.9"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
